html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

:root {
  tab-size: 4;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

hr {
  height: 0;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
}

.noUi-target {
  direction: ltr;
  position: relative;
}

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.noUi-connects {
  z-index: 0;
  overflow: hidden;
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  z-index: 1;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  position: absolute;
  top: 0;
  left: 0;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  transition: transform .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  top: -6px;
  left: -17px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  top: -17px;
  left: -6px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  left: auto;
  right: -17px;
}

.noUi-target {
  background: #fafafa;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  cursor: default;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:before, .noUi-handle:after {
  content: "";
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  display: block;
  position: absolute;
  top: 6px;
  left: 14px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  top: 14px;
  left: 6px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.noUi-pips, .noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  color: #999;
  position: absolute;
}

.noUi-value {
  white-space: nowrap;
  text-align: center;
  position: absolute;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  background: #ccc;
  position: absolute;
}

.noUi-marker-sub, .noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  height: 80px;
  width: 100%;
  padding: 10px 0;
  top: 100%;
  left: 0;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  width: 2px;
  height: 5px;
  margin-left: -1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  height: 100%;
  padding: 0 10px;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  color: #000;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 5px;
  display: block;
  position: absolute;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 120%;
  left: 50%;
  transform: translate(-50%);
}

.noUi-vertical .noUi-tooltip {
  top: 50%;
  right: 120%;
  transform: translate(0, -50%);
}

body {
  min-width: 960px;
  background: #f2ede0;
  font-size: 1rem;
}

:focus {
  outline: none;
}

a {
  color: #f2a51f;
  text-decoration: none;
  transition: color .2s;
}

a:hover {
  color: #f25d1f;
}

.wrapper {
  max-width: 1024px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 2em;
}

.header, .generator {
  text-align: center;
  background: #fdebbe;
  padding: 1em 0;
}

.generator .seed-text-container {
  text-align: center;
  background-color: #0000000d;
  border: 2px solid #0000001a;
  border-radius: 4px;
  margin-bottom: 1em;
  font-family: Comic Sans MS, Comic Sans, Comic Neue, cursive, sans-serif;
  font-size: 1.4em;
}

.generator .seed-text {
  padding: 1em .5em;
  top: 50%;
  left: 50%;
}

.generator .seed-text span:nth-of-type(1), .generator .seed-text span:nth-of-type(2) {
  color: red;
}

.generator .seed-text span:nth-of-type(3), .generator .seed-text span:nth-of-type(4) {
  color: #00f;
}

.generator .seed-text span:nth-of-type(5), .generator .seed-text span:nth-of-type(6) {
  color: orange;
}

.generator .seed-text span:nth-of-type(7), .generator .seed-text span:nth-of-type(8) {
  color: #ff0;
}

.generator .seed-text span:nth-of-type(9), .generator .seed-text span:nth-of-type(10) {
  color: olive;
}

.generator .seed-text span:nth-of-type(11), .generator .seed-text span:nth-of-type(12) {
  color: green;
}

.generator .seed-text span:nth-of-type(13), .generator .seed-text span:nth-of-type(14) {
  color: purple;
}

.generator .seed-text span:nth-of-type(15), .generator .seed-text span:nth-of-type(16) {
  color: #f0f;
}

.generator .seed-text span:nth-of-type(17), .generator .seed-text span:nth-of-type(18) {
  color: #32cd32;
}

.generator .seed-text span:nth-of-type(19), .generator .seed-text span:nth-of-type(20) {
  color: teal;
}

.generator .seed-text span:nth-of-type(21), .generator .seed-text span:nth-of-type(22) {
  color: #20b2aa;
}

.generator .seed-text span:nth-of-type(23), .generator .seed-text span:nth-of-type(24) {
  color: maroon;
}

.generator .seed-text[data-word-count="15"] span:nth-of-type(9):before, .generator .seed-text[data-word-count="18"] span:nth-of-type(11):before, .generator .seed-text[data-word-count="21"] span:nth-of-type(13):before, .generator .seed-text[data-word-count="24"] span:nth-of-type(13):before {
  content: "";
  display: block;
}

.bit-slider {
  max-width: 300px;
  margin: .4em auto 3em;
}

.bit-slider .noUi-pips {
  pointer-events: none;
  color: #0009;
  top: 8px;
}

.bit-slider .noUi-pips .noUi-marker {
  display: none;
}

.regenerate-seed {
  font: inherit;
  color: #fff;
  cursor: pointer;
  touch-action: manipulation;
  background-color: #52c752;
  border: none;
  border-radius: 3px;
  margin: 0 auto;
  padding: .6em 1em;
  transition: background-color .2s;
}

.regenerate-seed:hover {
  background-color: #42a042;
}

.description {
  padding: 1em 0;
}

code {
  background: #d8d8d8;
  border-radius: 2px;
  padding: .2em .4em;
  font-size: .8em;
}

table.entropy-table {
  border-spacing: 0;
  border-collapse: collapse;
}

table.entropy-table th, table.entropy-table td {
  border: 1px solid #0006;
  padding: 6px 13px;
}

table.entropy-table tr:nth-child(2n) {
  background-color: #fff6;
}

.footer {
  background: #ffffff80;
  padding: 1em 0;
}

.footer .me {
  float: right;
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 2rem;
  }

  .generator .seed-text {
    font-size: 1.2rem;
  }

  .wrapper {
    padding: 0 1em;
  }

  table.entropy-table {
    font-size: 1.6rem;
  }

  .footer {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 2.4rem;
  }

  .generator .seed-text {
    font-size: 1.4rem;
  }

  .footer {
    font-size: 1.6rem;
  }
}

@font-face {
  font-family: Comic Neue;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("comic-neue-latin-400-normal.b4996230.woff2") format("woff2"), url("comic-neue-all-400-normal.3b6f5004.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.a87a6451.css.map */
